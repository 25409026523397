import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    photo: null,
    username: localStorage.getItem("znkm-h5-username"),
    password: localStorage.getItem("znkm-h5-password"),
    hasSystmeUpdate: false,
    systemMessage: [],
  },

  mutations: {
    updateHasSystemUpdate(state, hasSystmeUpdate) {
      state.hasSystmeUpdate = hasSystmeUpdate
    },
    updateSystemMessage(state, systemMessage) {
      state.systemMessage = systemMessage
    },

    //  更新用户头像的方法 载荷 携带参数用的
    updatePhoto(state, payload) {
      state.photo = payload.photo
    },
    updateUser(state, payload) {
      state.user = payload.user // 更新数据
      //auth.setUser(payload.user) // 将更新得数据同步到本低存储中
    },
    rememberUser(state, userlogin) {


      state.username = userlogin.username;
      let p = userlogin.password + "$$$" + userlogin.username
      state.password = window.btoa(window.btoa(p));

      localStorage.setItem("znkm-h5-username", state.username)

      localStorage.setItem("znkm-h5-password", state.password)


    },



    // 清空数据
    clearUser(state) {
      state.user = {}
      //auth.delUser(state)
    }
  },
  getters: {


    gethasSystmeMessage(state) {
      return state.hasSystmeUpdate || state.systemMessage.length > 0;
    },
    gethasSystmeUpdate(state) {
      return state.hasSystmeUpdate;
    },
    getsystemMessage(state) {
      return state.systemMessage;
    },
    getMessage(state) {
      if (state.systemMessage.length > 0) {
        for (let index = 0; index < state.systemMessage.length; index++) {
          const element = state.systemMessage[index];
          if (element.messageType == 3) {
            return element
          }
        }
      }
      return {}

    },
    getMyComment(state) {
      if (state.systemMessage.length > 0) {
        for (let index = 0; index < state.systemMessage.length; index++) {
          const element = state.systemMessage[index];
          if (element.messageType == 1) {
            return element
          }
        }
      }
      return {}
    },
    getRememberUser: state => {


      if (state.username) {
        let passstr = window.atob(window.atob(state.password));
        passstr = passstr.substring(0, passstr.indexOf("$$$"))
        let u = { username: state.username, password: passstr }
        console.log(u);
        return u;
      }
      return null
    }
  },
  actions: {

  },
  modules: {
  }
})
