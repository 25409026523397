import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () => import('@/views/home')
const Long = () => import('@/views/long')
const Long2 = () => import('@/views/long2')
const Long3 = () => import('@/views/long3')
const Long4 = () => import('@/views/long4')
const Home220621 = () => import('@/views/home220621')
const Homeshanghai= () => import('@/views/homeshanghai')
const Homead = () => import('@/views/homead')
const Homeadw = () => import('@/views/homeadw')
const Comment = () => import('@/views/comment')
// const HS = () => import('@/views/hs')
const club= () => import('@/views/club')
const mytest= () => import('@/views/mytest')
const vip= () => import('@/views/vip')
const c1= () => import('@/views/c1')
const wx= () => import('@/views/wx')
const njxg = () => import('@/views/njxg')
const njxg2 = () => import('@/views/njxg2')
const njxg3 = () => import('@/views/njxg3')
Vue.use(VueRouter)
const routes = [

  { path: '/', name: 'Home', component: Home, meta: { title: "调研问卷" } },
  { path: '/vip', name: 'vip', component: vip, meta: { title: "" } },
  { path: '/c1', name: 'c1', component: c1, meta: { title: "" } },
  { path: '/wx', name: 'wx', component: wx, meta: { title: "" } },
  { path: '/Long', name: 'Long', component: Long, meta: { title: "调研问卷" } },
  { path: '/Long2', name: 'Long2', component: Long2, meta: { title: "龙年生肖酒产品调研" } },
  { path: '/Long3', name: 'Long3', component: Long3, meta: { title: "龙年生肖酒产品调研" } },
  { path: '/Long4', name: 'Long4', component: Long4, meta: { title: "龙年生肖酒产品调研" } },
  { path: '/AR4', name: 'AR4', component: Home, meta: { title: "产品调研问卷" } },
  { path: '/APP', name: 'APP', component: Home, meta: { title: "APP调研问卷" } },
  { path: '/club', name: 'club', component: club, meta: { title: "中酿名酒会预约" } },
  { path: '/club2', name: 'club2', component: club, meta: { title: "广州邮政名酒品鉴会报名" } },
  { path: '/dyadd', name: 'Home220621', component: Home220621, meta: { title: "活动问卷" }, props: { HasAddress: false } },
  { path: '/qg', name: 'Homead', component: Homead, meta: { title: "抢购活动" }, props: { HasAddress: false } },
  { path: '/qgw', name: 'Homeadw', component: Homeadw, meta: { title: "抢购活动" }, props: { HasAddress: false } },
  { path: '/dy', name: 'dy', component: Home, meta: { title: "活动问卷" }, props: { HasAddress: false } },
  { path: '/njxg', name: 'njxg', component: njxg, meta: { title: "" }, props: { HasAddress: true } },
  { path: '/njxg2', name: 'njxg2', component: njxg2, meta: { title: "" }, props: { HasAddress: true } },
  { path: '/njxg3', name: 'njxg3', component: njxg3, meta: { title: "" }, props: { HasAddress: true } },
  { path: '/dysh', name: 'dysh', component: Homeshanghai, meta: { title: "活动问卷" }, props: { HasAddress: false, } },
  { path: '/dytext', name: 'dytext', component: Home, meta: { title: "产品研发方案选择与意见征询" }, props: { HasAddress: false,HasUserName: false,HasPhone:false,HasText:true, } },

  { path: '/dy2', name: 'dy2', component: Home, meta: { title: "问卷调查" }, props: { HasAddress: false,HasUserName: false,HasPhone:false } },
  { path: '/dy3', name: 'dy3', component: Home, meta: { title: "问卷调查" }, props: { HasAddress: false,HasUserName: false,HasPhone:true } },
  // { path: '/kcpj', name: 'hs', component: KCPJ, meta: { title: "课程评价" } },
  { path: '/comment', name: 'comment', component: Comment, meta: { title: "中酿名酒会用餐评价" },props: { HasAddress: false,HasUserName: true,HasPhone:true }  },

  { path: '/dy2210', name: 'dy2210', component: Home, meta: { title: "“益企联盟·骏业百年”共享会问卷调查" }, props: { HasAddress: false,HasUserName: false,HasPhone:false, } },
  { path: '/dy2023', name: 'dy2023', component: Home, meta: { title: "龙年生肖酒调研问卷" }, props: { HasAddress: false } },
  { path: '/mytest', name: 'mytest', component: mytest, meta: { title: "龙年生肖酒调研问卷" }, props: { HasAddress: false } },
]


const router = new VueRouter({
  mode: 'history',
  base: '/card2023/',
  routes
})
router.beforeEach((to,_,next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

export default router
